<template>
  <div class="orderlist">
    404
  </div>
</template>

<script>
export default {
  name: "my404",
  data() {
    return {

    };
  },
  methods: {
    
  },
};
</script>
<style lang="scss" scoped>
.orderlist {
	width: 100%;
	height: 100%;
	background: #fff;
}

</style>
